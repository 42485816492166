import { Controller } from "stimulus"


export default class extends Controller {

  static targets = [ "reqType", "conditionSelect", "assignPoint", "evaluationPoint",
    'hazardList', 'matchRegex', 'ingNote', 'chemComp', "propertySelect",
    'materialRequirements', 'formulationRequirements', 'policyType'
  ]

  connect() {
    this.hideShowConditions();
    this.chooseAssignmentPoint();
  }


  hideShowConditions() {
    const a = !this.reqTypeTarget.value.includes("conditions")
    this.conditionSelectTarget.hidden = a;
  }

  chooseAssignmentPoint() {
    this.hazardListTarget.hidden = true;
    this.matchRegexTarget.hidden = true;
    this.ingNoteTarget.hidden = true;
    this.chemCompTarget.hidden = true;
    switch(this.assignPointTarget.value) {
      case 'chemicals_on_list':
        this.hazardListTarget.hidden = false;
        break;
      case 'chemicals_not_on_list':
        this.hazardListTarget.hidden = false;
        break;
      case 'chemicals_on_list_matching_regex':
        this.hazardListTarget.hidden = false;
        this.matchRegexTarget.hidden = false;
        break;
      case 'chemicals_with_ingredient_note':
        this.ingNoteTarget.hidden = false;
        break;
      case 'specific_chemical':
        this.chemCompTarget.hidden = false;
        break;
      case 'all_chemicals':
        break;
      case 'all_materials':
        break;
      case 'all_ingredients':
        break;
      case 'all_consumer_products':
        break;
      case 'all_packaging':
        break;
    }
  }


}


